import React, { FC, ReactElement, useMemo } from 'react';

import {
  StyledCardAccounts,
  StyledContentCardInfo,
  StyledDivider,
} from './styles';
import { formatNumber } from '../../utils/number';

interface Props {
  items?: any[];
}

const CardAccounts: FC<Props> = ({ items }): ReactElement => {
  const orderByName = (array: any[]): any[] => {
    return array.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  };

  const itemsToRender = useMemo(() => {
    return orderByName(items || []);
  }, [items]);

  return (
    <StyledCardAccounts>
      {itemsToRender?.map((item) => (
        <div
          key={item.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <StyledContentCardInfo>
            <div>{item.name}</div>
            <StyledDivider>-</StyledDivider>
            <div>{formatNumber(item.miles)} pontos</div>
          </StyledContentCardInfo>
        </div>
      ))}
    </StyledCardAccounts>
  );
};

export default CardAccounts;
